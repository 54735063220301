import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Container from "../components/layout/container";
import BottomSection from "../components/sections/bottomSection";
import TopSection from "../components/sections/topSection";
import payloadState from "../recoil/payloadState";
import image from "../assets/images/illustrations/payment-failed.png";
import OutlineButton from "../components/buttons/outlineButton";
import SolidButton from "../components/buttons/solidButton";
import { prepareParameters } from "../util/helpers";

export default function PaymentFailedPage() {
  const navigate = useNavigate();
  const payload = useRecoilValue(payloadState);
  const { state } = useLocation();
  const { message, partial, overall_payment_status } = state;

  return (
    <Container>
      <div>
        <TopSection />
        <div className="w-[22rem] p-8 sm:w-96">
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="relative h-56 w-56">
              <img src={image} alt="" />
            </div>
            <h2 className="text-xl font-medium text-lipad-red">
              Payment Failed
            </h2>
            <p>{message}</p>
            <p className="font-medium">
              Transaction Reference:{" "}
              {payload.merchant_site_data.merchant_transaction_id}
            </p>
          </div>
          <div className="mt-12 flex w-full gap-x-2">
            <div className="w-full">
              <OutlineButton
                label="Go Back"
                onClick={() => {
                  window.open(
                    `${
                      payload.merchant_site_data.fail_redirect_url
                    }?${prepareParameters({
                      merchant_transaction_id:
                        payload.merchant_site_data.merchant_transaction_id,
                      checkout_request_id: payload.checkout_request_id,
                      overall_payment_status: overall_payment_status,
                    })}`,
                    "_self"
                  );
                }}
              />
            </div>
            <div className="w-full">
              {partial !== null ? (
                <SolidButton
                  label="Retry"
                  onClick={() =>
                    navigate("/payment-partial", {
                      state: {
                        ...partial,
                      },
                    })
                  }
                />
              ) : (
                <Link to="/">
                  <SolidButton label="Retry" />
                </Link>
              )}
            </div>
          </div>
        </div>
        <BottomSection />
      </div>
    </Container>
  );
}
