/* eslint-disable no-throw-literal */
import { fetchWrapper } from "../util/helpers";

export async function createCheckoutInstance({
  accessKey,
  encryptedPayload,
  referrer,
}) {
  const response = await fetch(
    `${process.env.REACT_APP_CHECKOUT_ENDPOINT}/request`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer 123`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        access_key: accessKey,
        payload: encryptedPayload,
        referrer,
      }),
    }
  );

  const decodedResponse = await response.json();
  if (response.ok) {
    return decodedResponse;
  }
  throw Error({
    code: response.status,
    message: decodedResponse.message ?? "Failed to create a checkout instance",
  });
}

export async function getCheckoutRequestStatus({
  checkout_request_id,
  charge_request_id,
  token,
}) {
  const response = await fetchWrapper({
    // endpoint: `/request/${checkout_request_id}/status?chargeRequestID=${charge_request_id}`,
    endpoint: `/request/${checkout_request_id}/status`,
    method: "GET",
    token,
  });
  const decodedResponse = await response.json();
  if (response.ok) {
    return decodedResponse;
  }
  throw {
    code: decodedResponse.status,
    message: decodedResponse.message ?? "Failed to get checkout request status",
  };
}

export async function createCheckoutRequest({ body, token }) {
  const response = await fetchWrapper({
    endpoint: `/request`,
    method: "POST",
    token,
    body,
  });
  const decodedResponse = await response.json();
  if (response.ok) {
    window.open(
      `${process.env.REACT_APP_BASE_URL}?access_key=${decodedResponse.access_token}&payload=${decodedResponse.payload}`,
      "_self"
    );
  }
  throw Error({
    code: response.status,
    message: decodedResponse.message ?? "Failed to create checkout request",
  });
}

export async function getCheckoutStatus({
  checkout_request_id,
  country_code,
  token,
}) {
  const response = await fetchWrapper({
    endpoint: `/${checkout_request_id}/countries?country_code=${country_code}`,
    method: "GET",
    token,
  });
  const decodedResponse = await response.json();
  if (response.ok) return decodedResponse;
  throw Error({
    code: response.status,
    message: decodedResponse.message ?? "Failed to get checkout status",
  });
}

export async function getIPAddress() {
  const response = await fetch("https://geolocation-db.com/json/");
  const decodedResponse = await response.json();
  if (response.ok) return decodedResponse.IPv4;
  throw Error({
    code: response.status,
    message: decodedResponse.message ?? "Failed to get IP Address",
  });
}

export async function encryptPayload(payload) {
  const response = await fetchWrapper({
    endpoint: "/request/encrypt/test",
    method: "POST",
    body: payload,
  });
  const decodedResponse = await response.json();
  if (response.ok) return decodedResponse;
  throw Error({
    code: response.status,
    message: decodedResponse.message ?? "Failed to encrypt payload",
  });
}
