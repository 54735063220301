/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import image from "../assets/images/illustrations/person-reading-newspaper.png";
import SolidButton from "../components/buttons/solidButton";
import Container from "../components/layout/container";
import BottomSection from "../components/sections/bottomSection";
import TopSection from "../components/sections/topSection";
import { constants } from "../constants/constants";
import { useCountdown } from "../hooks/useCountdown";
import checkoutState from "../recoil/checkoutState";
import payloadState from "../recoil/payloadState";
import { getCheckoutRequestStatus } from "../services/checkout";

export default function PaymentPendingPage() {
  const toast = useToast();

  const payload = useRecoilValue(payloadState);
  const checkout = useRecoilValue(checkoutState);

  const navigate = useNavigate();
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);
  const [expiry, setExpiry] = useState(
    new Date().setSeconds(new Date().getSeconds())
  );
  const { countDown } = useCountdown(expiry);

  const checkPayment = async () => {
    setIsCheckingStatus(true);
    setExpiry(new Date().setSeconds(new Date().getSeconds() + 10));
    try {
      const response = await getCheckoutRequestStatus({
        checkout_request_id: payload.checkout_request_id,
        charge_request_id: checkout?.charge_request_id,
        token: payload.access_token,
      });
      processRedirect(response);
    } catch (error) {
      setExpiry(new Date().setSeconds(new Date().getSeconds()));
      toast({
        // title: error.code,
        description: "Failed to check payment status",
        status: "error",
        duration: constants.TOAST_DURATION,
        position: constants.TOAST_POSITION,
        isClosable: true,
      });
    }
    setIsCheckingStatus(false);
  };

  useEffect(() => {
    checkPayment();
  }, []);

  const processRedirect = (data) => {
    if (data.event_record.payment_status === 700) {
      if (data.overall_payment_status === 801) {
        navigate("/payment-successful");
      } else if (data.overall_payment_status === 802) {
        navigate("/payment-partial", {
          state: {
            currency_code: data.event_record.currency_code,
            requested_amount: data.request_amount,
            due_amount: data.outstanding_amount,
            paid_amount: data.event_record.amount,
            total_paid_amount: data.amount_paid,
          },
        });
      }
    } else if (data.event_record.payment_status === 701) {
      if (data.overall_payment_status === 803) {
        navigate("/payment-failed", {
          state: {
            message: data.event_record.receiver_narration ?? "",
            partial: null,
          },
        });
      } else if (data.overall_payment_status === 802) {
        navigate("/payment-failed", {
          state: {
            message: data.event_record.receiver_narration ?? "",
            partial: {
              currency_code: data.event_record.currency_code,
              requested_amount: data.request_amount,
              due_amount: data.outstanding_amount,
              paid_amount: data.event_record.amount,
              total_paid_amount: data.amount_paid,
            },
          },
        });
      }
    } else if (data.event_record.payment_status === 703) {
      navigate("/payment-pending");
    } else if (data.event_record.payment_status === 704) {
      navigate("/payment-jammed");
    }
  };

  return (
    <Container>
      <div>
        <TopSection />
        <div className="w-[22rem] p-8 sm:w-96">
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="relative h-56 w-56">
              <img src={image} alt="" />
            </div>
            <h2 className="text-xl font-medium text-lipad-orange">
              Payment Pending
            </h2>
            <p>Payment is pending processing</p>
            <p className="font-medium">
              Transaction Reference:{" "}
              {payload.merchant_site_data.merchant_transaction_id}
            </p>
          </div>
          <div className="mt-12">
            <SolidButton
              label="Confirm Payment"
              isSubmitting={isCheckingStatus || countDown >= 0}
              disabled={countDown >= 0}
              onClick={() => checkPayment()}
            />
          </div>
        </div>
        <BottomSection />
      </div>
    </Container>
  );
}
