/* eslint-disable react-hooks/exhaustive-deps */
import { Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useInitializeCheckout } from "./hooks/useInitializeCheckout";
import AuthorizePaymentPage from "./pages/authorizePaymentPage";
import BrowserPaymentPage from "./pages/browserPaymentPage";
import CardPage from "./pages/cardPage";
import ErrorPage from "./pages/errorPage";
import MobileBankingPage from "./pages/mobileBankingPage";
import MobileMoneyPage from "./pages/mobileMoneyPage";
import PaymentFailedPage from "./pages/paymentFailedPage";
import PaymentJammedPage from "./pages/paymentJammed";
import PaymentPartialPage from "./pages/paymentPartial";
import PaymentPendingPage from "./pages/paymentPendingPage";
import PaymentSuccessfulPage from "./pages/paymentSuccessful";
import ProcessingPaymentPage from "./pages/processingPaymentPage";
import SelectBankPage from "./pages/selectBankPage";
import SelectPaymentMethodPage from "./pages/selectPaymentMethodsPage";
import SessionExpiredPage from "./pages/sessionExpiredPage";
import TestPage from "./pages/testPage";
import payloadState from "./recoil/payloadState";

export default function App() {
  const navigate = useNavigate();
  const payload = useRecoilValue(payloadState);
  const { mounted, countDown } = useInitializeCheckout();
  const [sesionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    if (mounted && countDown !== null && countDown <= 0 && !sesionExpired) {
      setSessionExpired(true);
      navigate("/session-expired", {
        state: {
          message: "Your checkout request has expired.",
        },
      });
    }
  }, [countDown]);

  useEffect(() => {
    if (mounted) {
      document.documentElement.style.setProperty(
        "--primary-color",
        payload?.client_data?.checkout_configs.branding_configs.color ??
          "#21C463"
      );
      document.title =
        payload?.client_data?.checkout_configs.branding_configs.name ??
        "Checkout";
      document.getElementById("favicon").href =
        payload?.client_data?.checkout_configs.branding_configs.favicon ??
        "https://checkout.lipad.io/favicon.svg";
    }
  }, [mounted, payload]);

  if (!mounted)
    return (
      <div className="grid h-screen w-screen place-content-center">
        <Spinner color="green" size={"xl"} />
      </div>
    );

  return (
    <Routes>
      <Route path="/" element={<SelectPaymentMethodPage />} />
      <Route path="/select-bank" element={<SelectBankPage />} />
      <Route path="/mobile-money" element={<MobileMoneyPage />} />
      <Route path="/card" element={<CardPage />} />
      <Route path="/browser-payment" element={<BrowserPaymentPage />} />
      <Route path="/mobile-banking" element={<MobileBankingPage />} />
      <Route path="/authorize-payment" element={<AuthorizePaymentPage />} />
      <Route path="/process-payment" element={<ProcessingPaymentPage />} />
      <Route path="/payment-failed" element={<PaymentFailedPage />} />
      <Route path="/payment-jammed" element={<PaymentJammedPage />} />
      <Route path="/payment-pending" element={<PaymentPendingPage />} />
      <Route path="/payment-partial" element={<PaymentPartialPage />} />
      <Route path="/payment-successful" element={<PaymentSuccessfulPage />} />
      <Route path="/session-expired" element={<SessionExpiredPage />} />
      <Route path="/test" element={<TestPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
