import numeral from "numeral";
import { useRecoilValue } from "recoil";
import payloadState from "../../recoil/payloadState";
import CountDownTimer from "../timer/countDownTimer";
import uiState from "../../recoil/uiState";

export default function MiddleSection() {
  const payload = useRecoilValue(payloadState);
  const checkoutStatus = useRecoilValue(uiState);

  return (
    <div className="bg-primary text-white">
      <section className="pb-14 pt-[9px]">
        <CountDownTimer />
        <div className="mx-auto mt-[8px] h-0.5 w-8 bg-white"></div>
        <p className="mt-[12px] px-16 text-center text-sm font-medium">
          Make a payment to{" "}
          {payload.merchant_site_data.client_service.client_service_name}
        </p>
        <p className="mt-0 px-16 text-center text-sm">
          {payload.merchant_site_data.request_description}
        </p>
        <p className="mt-[15px] text-center text-xl font-medium">
          {payload.merchant_site_data.running_amounts.converted_currency ??
            payload.merchant_site_data.running_amounts
              .origin_currency_code}{" "}
          {numeral(
            checkoutStatus.running_amounts.converted_balance ??
              checkoutStatus.running_amounts.origin_balance
          ).format("0,0.00")}
        </p>
        <div className="mt-[5px] flex justify-center text-sm">
          <span>Account Reference:</span>
          <span className="ml-1">
            {payload?.merchant_site_data.account_number}
          </span>
        </div>
      </section>
    </div>
  );
}
