import { useRecoilValue } from "recoil";
import image from "../assets/images/illustrations/person-reading-newspaper.png";
import Container from "../components/layout/container";
import BottomSection from "../components/sections/bottomSection";
import TopSection from "../components/sections/topSection";
import payloadState from "../recoil/payloadState";

export default function PaymentJammedPage() {
  const payload = useRecoilValue(payloadState);

  return (
    <Container>
      <div>
        <TopSection />
        <div className="w-[22rem] p-8 sm:w-96">
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="relative h-56 w-56">
              <img src={image} alt="" />
            </div>
            <h2 className="text-xl font-medium text-lipad-orange">
              Payment Jammed
            </h2>
            <p>
              Please reach out to{" "}
              {payload.merchant_site_data.client_service.client_service_name}{" "}
              for assistance
            </p>
            <p className="font-medium">
              Transaction Reference:{" "}
              {payload.merchant_site_data.merchant_transaction_id}
            </p>
          </div>
        </div>
        <BottomSection />
      </div>
    </Container>
  );
}
