import { useRecoilValue } from "recoil";
import payloadState from "../../recoil/payloadState";

export default function BottomSection() {
  const payload = useRecoilValue(payloadState);

  return (
    <div className="mb-4 flex w-full justify-center ">
      <img
        className="h-12 w-24"
        src={
          payload?.client_data.checkout_configs.branding_configs.logo ??
          "https://lipad.io/images/logos/lipad-logo-blue-powered-by.svg"
        }
        alt=""
      />
    </div>
  );
}
