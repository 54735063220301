import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const uiState = atom({
  key: "uiState",
  effects_UNSTABLE: [persistAtom],
  default: {
    selectedCountry: {},
    checkoutStatus: {},
    encryptedPayload: "",
    accessKey: "",
  },
});

export default uiState;
