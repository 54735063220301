import { useLocation } from "react-router-dom";
import image from "../assets/images/illustrations/payment-failed.png";
import Container from "../components/layout/container";
import BottomSection from "../components/sections/bottomSection";

export default function ErrorPage() {
  const { state } = useLocation();
  const { message } = state;
  return (
    <Container>
      <div className="space-y-4 p-8">
        <h2 className="text-center text-lg font-medium">
          Something went wrong
        </h2>
        <img className="h-56 w-auto" src={image} alt="" />
        <p className="text-center font-medium text-red-600">{message}</p>
      </div>
      <BottomSection />
    </Container>
  );
}
