/* eslint-disable no-undef */
import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { HiChevronUp } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import card from "../../assets/images/icons/card.svg";
import mastercard from "../../assets/images/logos/mastercard.svg";
import visa from "../../assets/images/logos/visa.svg";
import { constants } from "../../constants/constants";
import useScript from "../../hooks/useScript";
import checkoutState from "../../recoil/checkoutState";
import payloadState from "../../recoil/payloadState";
import { sendPaymentRequest } from "../../services/payments";

const paymentIcons = [visa, mastercard];

export default function CardPaymentOptionsTile({ options }) {
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const payload = useRecoilValue(payloadState);
  const [checkout, setCheckout] = useRecoilState(checkoutState);

  // useScript("https://checkout.seerbitapi.com/api/v2/seerbit.js");
  // useScript(
  //   "https://unpkg.com/@fincra-engineering/checkout@2.2.0/dist/inline.min.js"
  // );

  function paywithSeerbit({ checkoutReference }) {
    SeerbitPay(
      {
        tranref: checkoutReference,
        currency: checkout.currency_code,
        description: "",
        country: checkout.country_code,
        amount: payload.merchant_site_data.request_amount,
        full_name: `${payload.merchant_site_data.customer_first_name} ${payload.merchant_site_data.customer_last_name}`,
        email: payload.merchant_site_data.customer_email,
        public_key: process.env.REACT_APP_SEERBIT_PUBLIC_KEY,
        setAmountByCustomer: false,
        customization: {
          theme: {
            border_color: "#000000",
            background_color: "#FFFFFF",
            button_color: "#21C463",
          },
          payment_method: ["card"],
          confetti: false,
          logo: "",
        },
      },
      async function callback(response, close) {
        close();
        navigate("/process-payment");
      }
    );
  }
  function paywithFincra({ checkoutReference }) {
    Fincra.initialize({
      reference: checkoutReference,
      key: "pk_NjMyNDYzMjkyMmY4MzIxMmRlNTk1NjVlOjoxNzMxNTk=",
      amount: Number(payload.merchant_site_data.request_amount),
      currency: "NGN",
      customer: {
        name: `${payload.merchant_site_data.customer_first_name} ${payload.merchant_site_data.customer_last_name}`,
        email: payload.merchant_site_data.customer_email,
        phoneNumber: payload.merchant_site_data.msisdn,
      },
      feeBearer: "business",
      paymentMethods: ["card"],
      metadata: {},
      // onClose: function () {
      //   alert("Transaction was not completed, window closed.");
      // },
      onSuccess: function (data) {
        const reference = data.reference;
        alert("Payment complete! Reference: " + reference);
      },
    });
  }

  const fetchCardCheckoutReference = async () => {
    setIsLoading(true);
    try {
      const response = await sendPaymentRequest({
        checkout_request_id: payload.checkout_request_id,
        payment_method_type: "card",
        token: payload.access_token,
        checkout: {
          ...checkout,
          payment_method_code: payload.client_data.payment_methods.find(
            (e) => e.payment_method_type === "card"
          ).payment_method_code,
        },
      });
      setCheckout((prevState) => ({
        ...prevState,
        charge_request_id: response.chargeRequestID,
      }));
      //TODO: Implement switching logic
      // if (checkout.payment_method_name === "")
      //   paywithFincra({ checkoutReference: response.fincraCheckoutReference });
      // else
      //   paywithSeerbit({
      //     checkoutReference: response.seerbitCheckoutReference,
      //   });
      // paywithFincra({ checkoutReference: response.fincraCheckoutReference });

      if (response.redirect === true) {
        navigate("/process-payment", {
          state: {
            src: response.checkout_redirect_url,
          },
        });
      } else {
        navigate("/card");
      }
    } catch (error) {
      toast({
        description: error.message,
        status: "error",
        duration: constants.TOAST_DURATION,
        position: constants.TOAST_POSITION,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      onClick={() => {
        setCheckout((prevState) => ({
          ...prevState,
          payment_method_code: options[0].payment_method_code,
          payment_method_name: options[0].payment_method_name,
        }));
        navigate("/card");
        //TODO: Implement switching logic
        // if (checkout.payment_method_name === "Card Kenya") navigate("/card");
        // else fetchCardCheckoutReference();
      }}
      className="bg flex w-full cursor-pointer flex-col rounded-lg bg-gray-50 p-4 shadow-md"
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center space-x-3">
          <img className="h-5 w-5" src={card} alt="" />
          <span className="text-sm font-medium sm:text-base">
            Credit/Debit Card
          </span>
        </div>
        <div className=" flex items-center gap-2">
          {paymentIcons.map((paymentIcon, i) => (
            <img key={i} className="h-auto w-8" src={paymentIcon} alt="" />
          ))}
          {isLoading ? (
            <svg
              className="text-primary -ml-1 mr-3 h-5 w-5 animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <HiChevronUp
              className={`h-5 w-5 rotate-90 transform text-lipad-grey`}
            />
          )}
        </div>
      </div>
    </button>
  );
}
