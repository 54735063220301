import { useField } from "formik";
import { BsExclamationCircleFill } from "react-icons/bs";

export default function CardExpiryInputField({ label, ...props }) {
  const [field, meta] = useField(props);

  return (
    <div>
      <label
        htmlFor={label}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          {...field}
          {...props}
          value={field.value
            .replace(/\D/g, "")
            .slice(0, 4)
            .replace(/(\d{2})(\d)/, "$1/$2")
            .trim()}
          maxLength="5"
          className={`${
            meta.touched && meta.error
              ? `border-red-300 text-red-900  focus:border-red-500 focus:ring-red-500`
              : `border-gray-300 focus:border-lipad-green focus:ring-lipad-green`
          } block w-full rounded-md pr-10 text-sm placeholder-gray-300 focus:outline-none sm:text-base`}
        />
        {meta.touched && meta.error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <BsExclamationCircleFill
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <p className="mt-1 text-sm text-red-600">
        {meta.touched && meta.error && <span>{meta.error}</span>}
      </p>
    </div>
  );
}
