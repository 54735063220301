/* eslint-disable no-restricted-globals */
import { useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import SolidButton from "../components/buttons/solidButton";
import Container from "../components/layout/container";
import BottomSection from "../components/sections/bottomSection";
import MiddleSection from "../components/sections/middleSection";
import TopSection from "../components/sections/topSection";
import { constants } from "../constants/constants";
import checkoutState from "../recoil/checkoutState";
import payloadState from "../recoil/payloadState";
import { uiState as _uiState } from "../recoil/uiState";
import { sendPaymentRequest } from "../services/payments";

function BrowserPaymentPage() {
  const toast = useToast();
  const payload = useRecoilValue(payloadState);
  const [checkout, setCheckout] = useRecoilState(checkoutState);
  const uiState = useRecoilValue(_uiState);
  const [loading, setLoading] = useState(false);

  const sendPaymentRequestFunc = async () => {
    setLoading(true);
    try {
      const response = await sendPaymentRequest({
        checkout_request_id: payload.checkout_request_id,
        payment_method_type: "browser",
        token: payload.access_token,
        checkout: {
          ...checkout,
          return_url: `https://dev.checkout.lipad.io?access_key=${uiState.accessKey}&payload=${uiState.encryptedPayload}&payment_method_type=browser-payment`,
        },
      });
      setCheckout((prevState) => ({
        ...prevState,
        charge_request_id: response.chargeRequestID,
      }));
      window.open(response.redirectUrl, "_self");
    } catch (error) {
      toast({
        description: "Failed to send payment request. Please try again later",
        status: "error",
        duration: constants.TOAST_DURATION,
        position: constants.TOAST_POSITION,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return (
    <Container>
      <div>
        <TopSection />
        <MiddleSection />
        <div className="-mt-8 space-y-4 rounded-t-xl bg-white p-4 sm:p-6">
          <div className="relative flex w-full justify-center">
            <Link to="/" className="absolute left-0 top-1">
              <HiOutlineChevronLeft className="h-5 w-5 cursor-pointer text-black" />
            </Link>
            <h2 className="text-center font-medium">{`Pay with ${checkout.payment_method_name}`}</h2>
          </div>
          <div className="flex justify-center">
            <img
              className="h-12 w-auto"
              src={
                payload.client_data.payment_methods.find(
                  (e) => e.payment_method_code === checkout.payment_method_code
                ).payment_method_icon
              }
              alt=""
            />
          </div>
          <p>
            In order to complete your transaction, we will transfer you over to{" "}
            {checkout.payment_method_name}'s secure servers.
          </p>
          <SolidButton
            label="Proceed"
            isSubmitting={loading}
            onClick={() => sendPaymentRequestFunc()}
          />
        </div>
        <BottomSection />
      </div>
    </Container>
  );
}

export default BrowserPaymentPage;
