/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useRecoilValue } from "recoil";

import Container from "../components/layout/container";
import BottomSection from "../components/sections/bottomSection";
import TopSection from "../components/sections/topSection";
import payloadState from "../recoil/payloadState";
import image from "../assets/images/illustrations/person-celebrating.png";
import numeral from "numeral";
import SolidButton from "../components/buttons/solidButton";
import { prepareParameters } from "../util/helpers";

export default function PaymentSuccessfulPage() {
  const payload = useRecoilValue(payloadState);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.open(
        `${payload.merchant_site_data.success_redirect_url}?${prepareParameters(
          {
            merchant_transaction_id:
              payload.merchant_site_data.merchant_transaction_id,
            checkout_request_id: payload.checkout_request_id,
            overall_payment_status: 801,
          }
        )}`,
        "_self"
      );
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container>
      <div>
        <TopSection />
        <div className="w-[22rem] p-8 sm:w-96">
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="relative h-56 w-56">
              <img src={image} alt="" />
            </div>
            <h2 className="text-primary text-xl font-medium">
              Payment Successful
            </h2>
            <p>
              Your payment of{" "}
              {payload.merchant_site_data.running_amounts.origin_currency_code}{" "}
              {numeral(
                payload.merchant_site_data.running_amounts
                  .origin_total_payable_amount
              ).format("0,0.00")}{" "}
              to{" "}
              <span className="font-medium">
                {payload.merchant_site_data.client_service.client_service_name}
              </span>{" "}
              was successfully completed.
            </p>
            <p className="font-medium">
              Transaction Reference:{" "}
              {payload.merchant_site_data.merchant_transaction_id}
            </p>
          </div>
          <div className="mt-8">
            <SolidButton
              onClick={() =>
                window.open(
                  `${
                    payload.merchant_site_data.success_redirect_url
                  }?${prepareParameters({
                    merchant_transaction_id:
                      payload.merchant_site_data.merchant_transaction_id,
                    checkout_request_id: payload.checkout_request_id,
                    overall_payment_status: 801,
                  })}`,
                  "_self"
                )
              }
              label="Okay"
            />
          </div>
        </div>
        <BottomSection />
      </div>
    </Container>
  );
}
