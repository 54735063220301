export const constants = {
  TOAST_DURATION: 5000,
  TOAST_POSITION: "top-right",
};

export const SAFARICOM_REGEX = new RegExp(
  "^(254)((([7])([0|1|2|9])([0-9]{7}))|(74)([0123456]{1})([0-9]{6})|(75)([789]{1})([0-9]{6})|(76)([89]{1})([0-9]{6})|((110)|(111)|(112)|(113)|(114)|(115))([0-9]{6}))$"
);
export const AIRTEL_REGEX = new RegExp(
  "^(254)(([7]([38]{1}([0-9]{7})|(([5][0]|[5][1]|[5][2]|[5][3]|[5][4]|[5][5]|[5][6]|[6][0]|[6][2]){1})([0-9]{6}))|((100)|(101)|(102)|(103)|(104)|(105)|(106))([0-9]{6})))$"
);
