import { Buffer } from "buffer";
import { countryCodes } from "../data/countryCodes";
import { AES, enc, SHA256, mode } from "crypto-js";

export const prepareParameters = (params) => {
  const preparedParameters = [];
  for (var key in params) {
    if (params.hasOwnProperty(key)) {
      preparedParameters.push(`${key}=${params[key]}`);
    }
  }
  return preparedParameters.join("&");
};

export const decodeBase64 = (encodedData) => {
  let buffer = Buffer.from(encodedData, "base64");
  let data = JSON.parse(buffer.toString("utf8"));
  return data;
};

export const filteredPaymentMethods = ({ payload, type }) => {
  return payload.client_data.payment_methods.filter(
    (e) => e.payment_method_type === type
  );
};

export function convertISO3toISO2(ISO2) {
  return countryCodes.find((e) => e.ISO3 === ISO2).ISO2;
}

export const fetchWrapper = ({ endpoint, method, token, body }) => {
  return fetch(`${process.env.REACT_APP_CHECKOUT_ENDPOINT}${endpoint}`, {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      // "Cache-Control": "no-cache, no-store, must-revalidate",
      // Pragma: "no-cache",
      // Expires: "0",
    },
    body: JSON.stringify(body),
  });
};

export const formatPhoneNumber = (phoneNumber, countryCode) => {
  return countryCode + phoneNumber;
};

export const encrypt = (payload) => {
  let jsonStringPayload = JSON.stringify(payload);
  let key = SHA256(process.env.REACT_APP_IV_KEY)
    .toString(enc.Hex)
    .substring(0, 16);
  let secret = SHA256(process.env.REACT_APP_CONSUMER_SECRET)
    .toString(enc.Hex)
    .substring(0, 32);
  const cipher = AES.encrypt(jsonStringPayload, secret, {
    iv: key,
    mode: mode.CFB,
  });
  let encryptedPayload = cipher.ciphertext.toString(enc.Base64);

  return {
    access_key: process.env.REACT_APP_ACCESS_KEY,
    payload: encryptedPayload,
  };
};
