import numeral from "numeral";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import image from "../assets/images/illustrations/person-celebrating.png";
import SolidButton from "../components/buttons/solidButton";
import Container from "../components/layout/container";
import BottomSection from "../components/sections/bottomSection";
import TopSection from "../components/sections/topSection";
import payloadState from "../recoil/payloadState";

export default function PaymentPartialPage() {
  const payload = useRecoilValue(payloadState);
  const { state } = useLocation();
  const {
    currency_code,
    requested_amount,
    due_amount,
    paid_amount,
    total_paid_amount,
  } = state;

  return (
    <Container>
      <div>
        <TopSection />
        <div className="w-[22rem] p-8 sm:w-96">
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="relative h-56 w-56">
              <img src={image} alt="" />
            </div>
            <h2 className="text-xl font-medium text-lipad-orange">
              Partial Payment
            </h2>
            <p>
              Your partial payment of {currency_code}{" "}
              {numeral(paid_amount).format("0,0.00")} to{" "}
              <span className="font-medium">
                {payload.merchant_site_data.client_service.client_service_name}
              </span>{" "}
              has been sent. Please complete your payment
            </p>
            <div className="w-full space-y-2 rounded bg-gray-100 px-4 py-2">
              <div className="flex justify-between border-b  pb-2">
                <p>Requested Amount</p>
                <p className="font-medium">
                  {currency_code} {numeral(requested_amount).format("0,0.00")}
                </p>
              </div>
              <div className="flex justify-between border-b  pb-2">
                <p>Paid Amount</p>
                <p className="font-medium">
                  {currency_code} {numeral(total_paid_amount).format("0,0.00")}
                </p>
              </div>
              <div className="flex justify-between border-b  pb-2">
                <p>Due Amount</p>
                <p className="font-medium">
                  {currency_code} {numeral(due_amount).format("0,0.00")}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <Link to="/">
              <SolidButton label="Complete Payment" />
            </Link>
          </div>
        </div>
        <BottomSection />
      </div>
    </Container>
  );
}
