import { HiChevronLeft } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import payloadState from "../../recoil/payloadState";
import { prepareParameters } from "../../util/helpers";
import CountryDropdown from "../dropdowns/countryDropdown";

export default function TopSection() {
  const location = useLocation();
  const payload = useRecoilValue(payloadState);

  return (
    <div className="relative w-full font-medium">
      <div className="absolute -top-8">
        <a
          href={`${
            payload.merchant_site_data.fail_redirect_url
          }?${prepareParameters({
            merchant_transaction_id:
              payload.merchant_site_data.merchant_transaction_id,
            checkout_request_id: payload.checkout_request_id,
            overall_payment_status: payload.overall_payment_status,
          })}`}
          className="flex w-fit items-center gap-2"
        >
          <HiChevronLeft className="h-6 w-auto" />
          <div>
            Back to{" "}
            {payload.merchant_site_data.client_service.client_service_name}
          </div>
        </a>
      </div>
      <div className="bg-primary flex items-center rounded-t-xl border-b border-white px-2 py-2">
        <div className="flex h-full w-full items-center gap-2">
          <div className="h-8 w-8 rounded-md bg-white p-1">
            <img
              className="h-full object-cover"
              src={payload.client_data.client_logo}
              alt=""
            />
          </div>
          <span className="text-white">
            {payload.merchant_site_data.client_service.client_service_name}
          </span>
        </div>
        {location.pathname === "/" &&
        payload.client_data.countries.length > 1 ? (
          <CountryDropdown countries={payload.client_data.countries} />
        ) : null}
      </div>
    </div>
  );
}
