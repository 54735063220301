import { useRecoilValue } from "recoil";
import OutlineButton from "../components/buttons/outlineButton";
import payloadState from "../recoil/payloadState";
import image from "../assets/images/illustrations/payment-failed.png";
import TopSection from "../components/sections/topSection";
import BottomSection from "../components/sections/bottomSection";
import Container from "../components/layout/container";
import { useLocation } from "react-router-dom";
import { prepareParameters } from "../util/helpers";

export default function SessionExpiredPage() {
  const payload = useRecoilValue(payloadState);
  const { state } = useLocation();
  const { message } = state;

  return (
    <Container>
      <div>
        <TopSection />
        <div className="w-[22rem] p-8 sm:w-96">
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="relative h-56 w-56">
              <img src={image} alt="" />
            </div>
            <h2 className="text-xl font-medium text-lipad-red">
              Session Expired
            </h2>
            <p>{message}</p>
          </div>
          <div className="mx-auto mt-12 w-full ">
            <div className="w-full">
              <OutlineButton
                onClick={() =>
                  window.open(
                    payload != null
                      ? `${
                          payload.merchant_site_data.fail_redirect_url
                        }?${prepareParameters({
                          merchant_transaction_id:
                            payload.merchant_site_data.merchant_transaction_id,
                          checkout_request_id: payload.checkout_request_id,
                          overall_payment_status:
                            payload.overall_payment_status,
                        })}`
                      : "",
                    "_self"
                  )
                }
                label="Go Back"
              />
            </div>
          </div>
        </div>
        <BottomSection />
      </div>
    </Container>
  );
}
