/* eslint-disable no-throw-literal */
import { fetchWrapper, prepareParameters } from "../util/helpers";

export async function getPaymentMethods({
  checkoutRequestId,
  countryCode,
  currencyCode,
  token,
}) {
  const response = await fetchWrapper({
    endpoint: `/${checkoutRequestId}/countries?${prepareParameters({
      country_code: countryCode,
      currency_code: currencyCode,
    })}`,
    method: "GET",
    token,
  });
  const decodedResponse = await response.json();
  if (response.ok) {
    return decodedResponse;
  }
  throw {
    code: response.status,
    message: decodedResponse.message ?? "Failed to get payment methods",
  };
}

export async function sendPaymentRequest({
  checkout,
  checkout_request_id,
  payment_method_type,
  token,
}) {
  const response = await fetchWrapper({
    endpoint: `/${payment_method_type}/request/${checkout_request_id}`,
    method: "PATCH",
    token,
    body: checkout,
  });
  const decodedResponse = await response.json();
  if (response.ok) {
    return decodedResponse;
  }
  throw {
    code: response.status,
    message: decodedResponse.message ?? "Failed to send payment request",
  };
}
