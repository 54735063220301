import gsap from "gsap";
import { useEffect, useState } from "react";
import { HiChevronUp } from "react-icons/hi";
import { FaExchangeAlt } from "react-icons/fa";
import { useRecoilValue } from "recoil";
import numeral from "numeral";
import uiState from "../../recoil/uiState";

export default function BodySection({ children }) {
  const checkoutStatus = useRecoilValue(uiState);
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const origin_currency_code =
    checkoutStatus.running_amounts.origin_currency_code;

  const animateSection = (showOrderSummary) => {
    if (showOrderSummary)
      gsap.to("#summary-section", {
        height: "auto",
        duration: 0.2,
        ease: "power1.inOut",
      });
    else
      gsap.to("#summary-section", {
        height: 0,
        duration: 0.2,
        ease: "power1.inOut",
      });
  };

  useEffect(() => {
    animateSection(showOrderSummary);
  }, [showOrderSummary]);

  return (
    <div className="-mt-8 space-y-3 rounded-t-xl bg-white p-4 sm:p-6">
      <div className="flex flex-col items-center">
        <button
          onClick={() => setShowOrderSummary(!showOrderSummary)}
          className="text-primary flex items-end gap-2"
        >
          <p className="text-sm">
            {showOrderSummary ? "Hide Order Summary" : "Show Order Summary"}
          </p>

          <HiChevronUp
            className={`${
              showOrderSummary ? "rotate-0 transform" : "rotate-180 transform"
            } h-5 w-5`}
          />
        </button>
      </div>
      <div
        id="summary-section"
        className="overflow-hidden rounded-lg bg-[#FAFAFA] text-sm"
      >
        <div className="space-y-8 p-4">
          <div className="space-y-2">
            <div className="flex justify-between border-b  pb-2">
              <p>Requested Amount</p>
              <p className="font-medium">
                {origin_currency_code}{" "}
                {numeral(
                  checkoutStatus.running_amounts.origin_total_payable_amount
                ).format("0,0.00")}
              </p>
            </div>
            <div className="flex justify-between border-b  pb-2">
              <p>Paid Amount</p>
              <p className="font-medium">
                {origin_currency_code}{" "}
                {numeral(
                  checkoutStatus.running_amounts.origin_amount_paid
                ).format("0,0.00")}
              </p>
            </div>
            <div className="flex justify-between border-b  pb-2">
              <p>Due Amount</p>
              <p className="font-medium">
                {origin_currency_code}{" "}
                {numeral(checkoutStatus.running_amounts.origin_balance).format(
                  "0,0.00"
                )}
              </p>
            </div>
          </div>
          {checkoutStatus.running_amounts.origin_currency_code !==
            checkoutStatus.running_amounts.converted_currency && (
            <div className="space-y-2">
              <div className="flex justify-between border-b  pb-2">
                <p className="font-medium">
                  {origin_currency_code} {numeral(1).format("0,0.00")}
                </p>

                <FaExchangeAlt className="h-5 w-5" />
                <p className="font-medium">
                  {checkoutStatus.running_amounts.converted_currency}{" "}
                  {numeral(checkoutStatus.running_amounts.exchange_rate).format(
                    "0,0.00"
                  )}
                </p>
              </div>
              <div className="flex justify-between">
                <p>Original Amount</p>
                <p className="font-medium">
                  {origin_currency_code}{" "}
                  {numeral(
                    checkoutStatus.running_amounts.origin_balance
                  ).format("0,0.00")}
                </p>
              </div>
              <div className="flex justify-between">
                <p>Converted Amount</p>
                <p className="font-medium">
                  {checkoutStatus.running_amounts.converted_currency}{" "}
                  {numeral(
                    checkoutStatus.running_amounts.converted_balance
                  ).format("0,0.00")}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
}
