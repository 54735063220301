/* eslint-disable no-throw-literal */
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { isValidPhoneNumber } from "libphonenumber-js";
import numeral from "numeral";
import React from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import * as Yup from "yup";
import SolidButton from "../components/buttons/solidButton";
import CheckBox from "../components/input/checkBox";
import InputField from "../components/input/inputField";
import MobileInputField from "../components/input/mobileInputField";
import Container from "../components/layout/container";
import BottomSection from "../components/sections/bottomSection";
import MiddleSection from "../components/sections/middleSection";
import TopSection from "../components/sections/topSection";
import {
  AIRTEL_REGEX,
  constants,
  SAFARICOM_REGEX,
} from "../constants/constants";
import { countryCodes } from "../data/countryCodes";
import checkoutState from "../recoil/checkoutState";
import payloadState from "../recoil/payloadState";
import uiState from "../recoil/uiState";
import { convertISO3toISO2, formatPhoneNumber } from "../util/helpers";

function MobileMoneyPage() {
  const toast = useToast();
  const navigate = useNavigate();
  const ui = useRecoilValue(uiState);
  const payload = useRecoilValue(payloadState);
  const [checkout, setCheckout] = useRecoilState(checkoutState);
  const checkoutStatus = useRecoilValue(uiState);

  let initialPhoneNumber = (
    payload.merchant_site_data.msisdn
      ? payload.merchant_site_data.msisdn
      : localStorage.getItem("mobileNumber") ?? ""
  ).replace("+", "");

  if (!initialPhoneNumber.startsWith(ui.selectedCountry.phone_code))
    initialPhoneNumber = "";
  else {
    if (
      checkout.payment_method_code === "MPESA_KEN" &&
      !SAFARICOM_REGEX.test(initialPhoneNumber)
    ) {
      initialPhoneNumber = "";
    }
    if (
      checkout.payment_method_code === "AIRTEL_KEN" &&
      !AIRTEL_REGEX.test(initialPhoneNumber)
    ) {
      initialPhoneNumber = "";
    }
    initialPhoneNumber = initialPhoneNumber.replace(
      ui.selectedCountry.phone_code,
      ""
    );
  }

  const initialValues = {
    mobileNumber: initialPhoneNumber,
    amount: numeral(
      checkoutStatus.running_amounts.converted_balance ??
        checkoutStatus.running_amounts.origin_balance
    ).format("0.00"),
    saveNumber: false,
  };

  const validatePhoneNumber = (value) => {
    if (value == null || value === undefined) return false;
    // return value.length === 9 || value.length === 10;
    // try {
    //   const ISO2CountryCode = convertISO3toISO2(
    //     ui.selectedCountry.country_code
    //   );
    //   const countryPhoneCode = countryCodes
    //     .find((e) => e.ISO2 === ISO2CountryCode)
    //     .phoneCode.toString();
    //   return isValidPhoneNumber(
    //     countryPhoneCode + value.toString(),
    //     ISO2CountryCode
    //   );
    // } catch (error) {
    //   return false;
    // }
  };

  const validationSchema = Yup.object({
    mobileNumber: Yup.number("Invalid phone number").required("Required"),
    // .test(
    //   "phoneNumberValidation",
    //   "Invalid phone number",
    //   validatePhoneNumber
    // ),
    amount: Yup.number().required("Required"),
    saveNumber: Yup.bool(),
  });

  const onSubmit = (values, { setSubmitting }) => {
    try {
      // if (
      //   checkout.payment_method_code === "MPESA_KEN" &&
      //   !SAFARICOM_REGEX.test(
      //     formatPhoneNumber(values.mobileNumber, ui.selectedCountry.phone_code)
      //   )
      // )
      //   throw {
      //     message: "Please use a Safaricom mobile number",
      //   };
      // if (
      //   checkout.payment_method_code === "AIRTEL_KEN" &&
      //   !AIRTEL_REGEX.test(
      //     formatPhoneNumber(values.mobileNumber, ui.selectedCountry.phone_code)
      //   )
      // )
      //   throw {
      //     message: "Please use an Airtel mobile number",
      //   };
      setCheckout((prevState) => ({
        ...prevState,
        payer_msisdn: formatPhoneNumber(
          values.mobileNumber,
          ui.selectedCountry.phone_code
        ),
        request_amount: values.amount,
      }));
      if (values.saveNumber)
        localStorage.setItem("mobileNumber", values.mobileNumber);
      navigate("/authorize-payment");
    } catch (error) {
      toast({
        description: error.message,
        status: "error",
        duration: constants.TOAST_DURATION,
        position: constants.TOAST_POSITION,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const tabs = ["STK Push"];
  if (checkout.payment_method_code === "MPESA_KEN") {
    tabs.push("How to Pay");
  }

  return (
    <Container>
      <div>
        <TopSection />
        <MiddleSection />
        <div className="-mt-8 space-y-2 rounded-t-xl bg-white p-4 sm:p-6">
          <div className="relative">
            <Link to="/">
              <HiOutlineChevronLeft className="absolute inset-y-0 left-0 h-full w-5 cursor-pointer text-black" />
            </Link>
            <h2 className="text-center font-medium">{`Pay with ${checkout.payment_method_name}`}</h2>
          </div>
          <Tabs
            colorScheme="lipadGreen"
            variant="line"
            size="lg"
            isFitted
            isLazy
          >
            <TabList>
              {tabs.map((e, i) => (
                <Tab key={i}>
                  <h2 className="text-center text-sm font-medium">{e}</h2>
                </Tab>
              ))}
            </TabList>

            <TabPanels>
              <TabPanel padding={0}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {(props) => (
                    <form
                      onSubmit={props.handleSubmit}
                      className="mt-4 flex flex-col gap-2 text-sm sm:text-base"
                    >
                      <MobileInputField
                        name="mobileNumber"
                        type="tel"
                        label="Mobile number"
                        placeholder="XXX XXX XXX"
                        flag={ui.selectedCountry.flag}
                        phoneCode={ui.selectedCountry.phone_code}
                      />
                      <InputField
                        name="amount"
                        type="number"
                        disabled={
                          payload.client_data.checkout_configs
                            ?.checkout_settings.allow_split_payments === 1
                            ? false
                            : true
                        }
                        label={`Amount (${
                          payload.merchant_site_data.running_amounts
                            .converted_currency ??
                          payload.merchant_site_data.running_amounts
                            .origin_currency_code
                        })`}
                        placeholder="100"
                      />
                      <CheckBox
                        name="saveNumber"
                        label="Save my information for faster checkout"
                      />
                      <div className="mt-4 w-full">
                        <SolidButton type="submit" label="Confirm Details" />
                      </div>
                    </form>
                  )}
                </Formik>
              </TabPanel>
              {checkout.payment_method_code == "MPESA_KEN" && (
                <TabPanel>
                  <div className="space-y-2">
                    <h2 className="font-medium">Payment Instructions</h2>
                    <ol className="list-decimal  px-4">
                      <li>
                        Go to <strong>LIPA NA MPESA</strong>
                      </li>
                      <li>Select Paybill</li>
                      <li>
                        Enter <strong>4087453</strong> as Paybill Number
                      </li>
                      <li>
                        Enter <strong>{payload?.checkout_reference}</strong> as
                        Account Number
                      </li>
                      <li>
                        Enter{" "}
                        <strong>
                          {payload.merchant_site_data.running_amounts
                            .converted_currency ??
                            payload.merchant_site_data.running_amounts
                              .origin_currency_code}{" "}
                          {numeral(
                            checkoutStatus.running_amounts.converted_balance ??
                              checkoutStatus.running_amounts.origin_balance
                          ).format("0,0.00")}
                        </strong>{" "}
                        as amount
                      </li>
                      <li>Confirm your payment</li>
                      <li>Click "Confirm Payment" below</li>
                    </ol>
                    <div className="w-full pt-2">
                      <SolidButton
                        label="Confirm Payment"
                        onClick={() => {
                          setCheckout((prevState) => ({
                            ...prevState,
                            payer_msisdn: formatPhoneNumber(
                              initialPhoneNumber,
                              ui.selectedCountry.phone_code
                            ),
                            request_amount:
                              checkoutStatus.running_amounts
                                .converted_balance ??
                              checkoutStatus.running_amounts.origin_balance,
                          }));
                          navigate("/process-payment", {
                            state: {
                              payment_method_type: "mobile_money",
                              timeout: 3000,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </div>
        <BottomSection />
      </div>
    </Container>
  );
}

export default MobileMoneyPage;
