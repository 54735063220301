import { HiChevronUp } from "react-icons/hi";
import { Link } from "react-router-dom";
import bankTransfer from "../../assets/images/icons/bank.svg";

export default function BankTransferPaymentOptionsTile({ options }) {
  return (
    <Link to="/select-bank">
      <div className="mt-2 flex w-full flex-col rounded-lg bg-gray-50 p-4 shadow-md">
        <div className="flex w-full items-center justify-between">
          <div className="flex gap-2">
            <div className="relative h-5 w-5">
              <img src={bankTransfer} alt="" />
            </div>
            <span className="text-sm font-medium sm:text-base">
              Bank Transfer
            </span>
          </div>
          <div className=" flex items-center gap-2">
            {options.slice(0, 2).map((option, i) => (
              <img
                key={i}
                className="h-6 w-10"
                src={option.payment_method_icon}
                alt=""
              />
            ))}
            <HiChevronUp
              className={`h-5 w-5 rotate-90 transform text-lipad-grey`}
            />
          </div>
        </div>
      </div>
    </Link>
  );
}
