import { HiChevronUp } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import card from "../../assets/images/icons/card.svg";
import checkoutState from "../../recoil/checkoutState";

export default function UnionPayPaymentOptionTile({ options }) {
  const navigate = useNavigate();
  const [checkout, setCheckout] = useRecoilState(checkoutState);

  return (
    <button
      onClick={() => {
        setCheckout((prevState) => ({
          ...prevState,
          payment_method_code: options.payment_method_code,
          payment_method_name: options.payment_method_name,
        }));
        navigate("/browser-payment");
      }}
      className="mt-2 flex w-full flex-col rounded-lg bg-gray-50 p-4 shadow-md"
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="relative h-5 w-5">
            <img src={card} alt="" />
          </div>
          <span className="font-medium">UnionPay</span>
        </div>
        <div className="hidden items-center gap-4 sm:flex">
          <img className="h-6 w-10" src={options.payment_method_icon} alt="" />
          <HiChevronUp
            className={`h-5 w-5 rotate-90 transform text-lipad-grey`}
          />
        </div>
      </div>
    </button>
  );
}
