import React from "react";

function Container({ children }) {
  return (
    <div className="mx-auto grid min-h-screen w-full place-content-center rounded-b-xl px-6 py-8 sm:w-[24rem] sm:p-0">
      <div className="w-full rounded-xl shadow-2xl">{children}</div>
    </div>
  );
}

export default Container;
